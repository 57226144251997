import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { PhoneIcon } from '@heroicons/react/20/solid'
import { Cog6ToothIcon } from '@heroicons/react/24/outline'
import IframeResizer from 'iframe-resizer-react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Menu from '../components/menu'
import Seo from '../components/seo'
import Wordmark from '../../assets/wordmark.svg'

const SolutionsPage = ({ active = `solutions` }) => (
  <Layout>
    <Seo title="My Work and Well-Being | First Nations HRM and Well-Being" />
    <div className="max-w-[1200px] mx-auto">
      <div className="mx-auto px-6 py-6 lg:flex lg:items-baseline lg:justify-between lg:px-8 text-center h-auto">
        <Wordmark className="max-w-sm inline pb-14 lg:pb-0" />
        <Menu active={active} />
      </div>
    </div>
    <div className="h-60 bg-myorange"></div>
    <div className="max-w-[1200px] mx-auto -mt-44">
      <p className="text-white text-3xl lg:text-4xl pb-4 pl-6 font-tbold">
        First Nations HRM and Well-Being
      </p>
      <StaticImage
        src="../images/image-drums.jpg"
        alt="Decorative image of a drums"
        className="rounded-2xl m-6 lg:p-0"
      />
    </div>
    <section className="py-12 max-w-[1200px] mx-auto bg-white">
      <div className="md:max-w-4xl px-8 md:mx-auto">
        <h3 className="text-mypurple text-2xl md:text-3xl mb-8">Solutions</h3>
        <h2 className="text-4xl md:text-5xl mb-12 text-myorange">
          Leadership, HRM, Mental Health and Well-Being at work in First Nations
          organizations
        </h2>
        <p className="text-lg md:text-xl mb-6 text-gray-800 text-left"></p>
        <div>
          <p className="text-left text-mypurple text-lg font-bold">
            An overview:
          </p>
          <ul className="text-xl md:text-2xl lg:basis-1/2 text-left text-gray-600 tracking-tight font-tbold checks">
            <li className="mt-4">
              Aboriginal Human Resources Management Services
            </li>
            <li className="mt-4">Workplace Employment Equity and Inclusion</li>
            <li className="mt-4">Talent Management</li>
            <li className="mt-4">Succession Planning</li>
            <li className="mt-4">
              Transition and Career Pathing for Aboriginal People
            </li>
            <li className="mt-4">
              Mental Health at work in First Nations Organizations
            </li>
            <li className="mt-4">
              Employee Engagement and Well-being in First Nations Organizations
            </li>
            <li className="mt-4">
              Leadership and Well-Being Strategies for Chief and Council, First
              Nations Managers, and Staff
            </li>
            <li className="mt-4">Band Management Leadership Services</li>
            <li className="mt-4">Aboriginal Workplace Health and Well-Being</li>
            <li className="mt-4">Aboriginal Economic Development Services</li>
            <li className="mt-4">
              Any customized services in keeping with the requirements of the
              First Nations Organization
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section className="pt-24 pb-12 my-12 max-w-[1200px] mx-auto bg-slate-50">
      <div className="md:max-w-4xl px-8 md:mx-auto">
        <p className="text-2xl md:text-3xl mb-6 text-gray-800 text-center">
          "Partnering and Including First Nations Communities, you create
          Economic Certainty…this can be done through building respectful
          relationships;…having a representative workforce strategy in your
          Industry that helps with the employment of First Nations people at all
          levels of your organization. The private sector is looking at how to
          recruit First Nations people for certain jobs…. It's important to
          respect diversity so that young First Nations people can compete
          provincially, nationally, and globally. A gap that should be closed is
          an investment in Human Capital through good Recruitment and Retention
          Strategy because First Nations Youths are the highest growing
          population in Canada….We should all work together in partnership for a
          better future."
        </p>
        <p className="text-2xl md:text-3xl mb-6 text-gray-800 text-center">
          - Perry Bellegarde, National Chief, Assembly of First Nations -
          Keynote Address at the 15th Annual BC Natural Resources Forum, January
          17th, 2018
        </p>
      </div>
    </section>
    <section className="flex flex-nowrap items-center justify-evenly p-6 my-12 bg-mypurple max-w-[1200px] mx-auto">
      <div className="flex items-center mr-6 text-white">
        <Cog6ToothIcon className="h-12 w-12" />{' '}
        <span className="text-2xl lg:text-3xl pl-2">
          Ready to Shift Gears and Take Action?
        </span>
      </div>
      <div className="flex items-center flex-shrink-0 mr-6 text-white font-tbold">
        <Link
          type="button"
          to="/bookcall"
          className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm text-mypurple shadow-sm hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <PhoneIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
          Book a Call
        </Link>
      </div>
    </section>
    <section>
      <div className="max-w-[1200px] mx-auto my-12">
        <StaticImage
          src="../images/slider-boardroom.jpg"
          alt="Decorative image of a healthy workplace team"
          className="rounded-2xl m-6 lg:p-0"
        />
      </div>
    </section>
    <section className="py-24">
      <div className="h-80 mx-auto inline">
        <IframeResizer
          title="iframe embed"
          src="https://wallembed.famewall.io/carousel/mwwb-fn?autoplay=1"
          checkOrigin={false}
          autoResize={true}
          scrolling={false}
          width="100%"
          style={{
            border: 0,
            width: `1px`,
            minWidth: `100%`,
          }}
        />
      </div>
    </section>
    <section>
      <div className="max-w-[1200px] mx-auto my-12">
        <StaticImage
          src="../images/image-beaver.jpg"
          alt="Canadian Beaver"
          className="rounded-2xl m-6 lg:p-0"
        />
      </div>
    </section>
    <section>
      <div className="max-w-[1200px] mx-auto my-24">
        <iframe
          width="100%"
          height="600"
          src="https://www.youtube.com/embed/g320ycpvCto"
          title="Video"
        ></iframe>
      </div>
    </section>
  </Layout>
)

export default SolutionsPage
